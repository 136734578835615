import { EntityInterface } from '../services/entity.service';
import { Article, Order, Product } from '@proman/interfaces/entity-interfaces';

export const product = {
    name: 'product',
    params: {
        entityConfig: {
            name: 'product',
            post: [
                'importData',
                'moveToWarehouse',
                'merge',
                'barcode',
                'evaluatePackageQuantity',
                'evaluateProductQuantity',
                'purchase',
                'purchase_ocr',
                'changeArticle',
                'productsOnStock',
                'posCreate',
                'posCreate_',
                'reevaluateWeight',
                'reevaluateCapacity',
                'reevaluateExtent',
                'reevaluatePackagingSize',
                'createProductsOrder',
            ],
            get: [
                'lessMinQuantity',
            ],
            uris: {
                importOrderUri: 'import_order',
                readFromFileUri: 'read_from_file',
                importUrl: 'import'
            },
            attrs: {
                // TYPES
                PRODUCTS: 'products',
                SERVICES: 'services',
                ASSETS: 'assets',
                MATERIALS: 'materials',
                INVENTORY: 'inventory',
                EXPENSES: 'expenses',
                TAXES: 'taxes',

                // STATUSES
                STOCK: 'stock',
                SOLD: 'sold',
                WRITTEN_OFF: 'written_off',
                SHIPPED: 'shipped',
                STANDBY: 'standby',
                REJECTED: 'rejected',
                QUARANTINE: 'quarantine',
                IN_TRANSIT: 'in_transit',
                PURCHASE: 'purchase',
                ARRIVED: 'arrived',
            },
            QueryBuilder: {
                aggregate() {
                    return {
                        join: [
                            'article',
                            'photo',
                            'parameters',
                            'parameters.parameter'
                        ]
                    };
                }
            }
        },
        get: {
            join: [
                'article',
                'photo',
                'testFile',
                'toleranceFile',
                'testFiles',
                'toleranceFiles',
                'parameters',
                'parameters.parameter',
                'warehouseLocation',
                'warehouseLocation.warehouse',
                'accountCategoryPurchase',
                'accountCategoryInvoice',
                'productBarcode',
                'purchase',
                'suppliers',
                'defaultSupplier',
                'defaultSupplier.vat',
                'defaultSupplier.accountCategory',
                'vat',
            ]
        },
        extraParameters() {
            return {
                'join': [
                    'warehouseLocation',
                    'warehouseLocation.warehouse',
                    'productBarcode',
                    'photo',
                ],
                'partialJoin': {
                    'article': ['name', 'id', 'altName', 'packagingQuantityExpression', 'storedQuantityExpression', 'weightUnit'],
                    'vat': ['name', 'percentage', 'discountApplies'],
                    'article.categories': ['name, id'],
                    'article.type': ['name, id'],
                    'orderProducts': ['id'],
                    'orderProducts.order': ['name', 'number', 'dispatchDate', 'customerNumber'],
                    'orderProducts.order.customer': ['name']
                },
                'sort': { 'id': 'desc' },
                'article.isStoredAsProduct': true,
                '_column' : {
                    remnantQuantity: 'storedQuantity - reservedQuantity'
                }
            };
        },
        searchFields: [
            'id',
            'name',
            'orderProducts.productionProducts.production.id',
            'orderProducts.order.productions.name',
            'orderProducts.order.customer.name',
            'productBarcode.value',
            'customerName',
        ]
    }
};

export interface ProductEntityInterface extends EntityInterface<Product> {
    // TYPES
    PRODUCTS: 'products';
    SERVICES: 'services';
    ASSETS: 'assets';
    MATERIALS: 'materials';
    INVENTORY: 'inventory';
    EXPENSES: 'expenses';
    TAXES: 'taxes';

    // STATUSES
    STOCK: 'stock';
    SOLD: 'sold';
    WRITTEN_OFF: 'written_off';
    SHIPPED: 'shipped';
    STANDBY: 'standby';
    REJECTED: 'rejected';
    QUARANTINE: 'quarantine';
    IN_TRANSIT: 'in_transit';
    PURCHASE: 'purchase';
    ARRIVED: 'arrived';

    // URI's
    importUrl: 'import';
    importOrderUri: 'import_order';

    barcode: (data: { product: number; barcodeValue: string }) => Promise<any>;
    merge: (data: { ids: number[] }) => Promise<any>;
    lessMinQuantity: () => Promise<Product[]>;
    changeArticle: (data: { id: number; article: number }) => Promise<void>;
    moveToWarehouse: (data: { id: number; warehouseLocationId: number; quantity: number; packagingQuantity?: number }) => Promise<any>;
    evaluatePackageQuantity: (data: { id: number; packagingQuantity: number }) => Promise<any>;
    evaluateProductQuantity: (data: { id: number; productQuantity: number }) => Promise<any>;
    readFromFileUri: (data: { orderId: any; dry: any }) => Promise<any>;
    importOrder: (data: { orderId: any; dry: any }) => Promise<any>;
    purchase: (data: { items: any[] }) => Promise<any>;
    purchase_ocr: (data: { items: any[] }) => Promise<any>;
    productsOnStock: (data: { articlesIds: number[] }) => Promise<unknown>;
    reevaluateWeight: (data: { ids: number[] }) => Promise<unknown>;
    reevaluateCapacity: (data: { ids: number[] }) => Promise<unknown>;
    reevaluateExtent: (data: { ids: number[] }) => Promise<unknown>;
    reevaluatePackagingSize: (data: { ids: number[] }) => Promise<unknown>;
    posCreate: (data: {
        article: Article;
        quantity: number|string;
        order: number;
        name: string;
        itemNumber: number;
        position: number;
        parentProduct: Product;
        parameters: unknown; }) => Promise<unknown>;
    posCreate_: (data: {
        article: Article;
        quantity: number|string;
        order: number;
        name: string;
        itemNumber: number;
        position: number;
        parentProduct: Product;
        parameters: unknown;
        price?: number|'';
    }) => Promise<Order[]>;
    createProductsOrder: (data: { ids: number[], customer: number, manager: number, name: string, type?: number }) => Promise<number>;


    // smarttag
  generateQr: (data: { data: string, errorCorrectionLevel?: 0|1|2|3|4, size?: number,
    margin?: number, roundBlockSizeMode?: 0|1|2|3, logoPath?: string, labelText?: string,
    defaultFont?: 0|1, labelFontSize?: number, labelAlignment: 0|1|2, writer: 'eps'|'png'|'svg'|'pdf' }) => Promise<unknown>;  }
